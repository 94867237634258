import { Modal, Input } from '@o-clock-dev/mooncake';
import React, { useState, useImperativeHandle, useCallback } from 'react';
import ApiCaller from 'src/commons/ApiCaller';
import { useError, useSuccess } from 'src/commons/MessagesProvider';
import PropTypes from 'prop-types';

const { TextArea } = Input;

const ModalArchivageProspect = React.forwardRef(({ onArchived }, ref) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [withArchivageState, setArchivageState] = useState(true);
  const [comment, setCommentValue] = useState('');
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [prospect, setProspect] = useState({});

  const showSuccess = useSuccess();
  const showError = useError();

  const onCommentChange = useCallback((event) => {
    setCommentValue(event.target?.value);
  }, []);

  const handleOk = () => {
    setConfirmLoading(true);

    ApiCaller.makeRequest('PUT', `/prospects/${prospect?._id}/archive`, {
      comment,
      isArchived: withArchivageState,
    })
      .then((prospectsList) => {
        showSuccess(`Le prospect a été ${withArchivageState ? '' : 'dés'}archivé avec succès`);
        setIsModalVisible(false);
        setCommentValue('');
        onArchived?.(prospectsList);
      }).catch((error) => {
        let message = error.message;
        if (error?.response?.data?.message) {
          message = error?.response?.data?.message;
        }
        showError(message, 'Erreur durant le changement d\'état d\'archivage du prospect');
      }).finally(() => {
        setConfirmLoading(false);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setProspect({});
    setCommentValue('');
    setArchivageState(false);
  };

  useImperativeHandle(ref, () => ({
    showModal: (_prospect, archivageState = true) => {
      setArchivageState(archivageState);
      setProspect(_prospect);
      setIsModalVisible(true);
      setCommentValue('');
    },
  }), []);

  return (
    <Modal
      title={`Changement d'état d'archivage du prospect ${prospect?.firstname} ${prospect?.lastname}`}
      open={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
      cancelText="Annuler"
      okText={withArchivageState ? 'Archiver le prospect' : 'Désarchiver le prospect'}
    >
      <TextArea
        rows={4}
        onChange={onCommentChange}
        value={comment}
        placeholder={`Commentaire à ajouter à ${prospect?.firstname} ${prospect?.lastname} pour ${withArchivageState ? '' : 'dés'}archivage`}
      />
    </Modal>
  );
});

ModalArchivageProspect.propTypes = {
  onArchived: PropTypes.func,
};

export default ModalArchivageProspect;

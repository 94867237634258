/* eslint-disable no-nested-ternary */
import React from 'react';
import PropTypes from 'prop-types';
import Forbidden from 'src/pages/Forbidden';

import { useAuthorization } from 'src/commons/KeycloakProvider';

const PrivateRoute = ({
    children = null,
    role,
    ...rest
}) => {
    const isAuthorized = useAuthorization(role);
    return (isAuthorized ? (
        children
    ) : (
        <Forbidden />
    ))
};

PrivateRoute.defaultProps = {
    children: null,
};

PrivateRoute.propTypes = {
    children: PropTypes.any,
    role: PropTypes.string.isRequired,
};

export default PrivateRoute;

import * as DS from '@o-clock-dev/mooncake';

function UploadEventBright() {
  return (
    <div style={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
    >
      <DS.Card title={<DS.Typography.Title>403 - J'appelle les flics !</DS.Typography.Title>} style={{ maxWidth: '100%', marginTop: 50, overflow: 'auto' }}>
        <DS.Typography.Title level={3}>Tu n'es pas autorisé à être ici, vas te plaindre à ton coordinateur</DS.Typography.Title>
      </DS.Card>
    </div>
  );
}

export default UploadEventBright;

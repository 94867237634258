import * as DS from '@o-clock-dev/mooncake';
import { PhoneOutlined, CommentOutlined, UserDeleteOutlined, CheckOutlined } from '@ant-design/icons';
import ReservationButton from 'src/pages/ProspectsList/ReservationButton';

export default ({
  searchParams,
  userIdentity,
  onProspectListUpdated,
  canEdit,
  modalCallDeclarationRef,
  modalArchiveProspectRef,
  modalValidateProspectRef,
}) => ({
  title: 'Action',
  key: 'action',
  width: 200,
  defaultFilteredValue: searchParams.get('action')?.trim() ? searchParams.get('action')?.split(',') : [],
  filters: [
    {
      text: 'Ceux qui sont attribués aux autres',
      value: 'withOthersReservation',
    },
    {
      text: 'Ceux qui me sont attribués',
      value: 'withMyReservation',
    },
    {
      text: 'Ceux qui ne sont pas attribués',
      value: 'withoutReservation',
    },
  ],
  onFilter: (value, record) => {
    if (value === 'withOthersReservation') {
      return record?.reservation?.keycloakUserId && record?.reservation?.keycloakUserId !== userIdentity?.sub;
    }
    if (value === 'withMyReservation') {
      return record?.reservation?.keycloakUserId === userIdentity?.sub;
    }
    if (value === 'withoutReservation') {
      return !record?.reservation?.keycloakUserId;
    }
    return true;
  },
  render: (text, record) => (
    <DS.Space size="middle">
      <DS.Space>
        <ReservationButton record={record} onDone={onProspectListUpdated} />
        <DS.Tooltip placement="topLeft" title="Déclarer un appel avec ce prospect">
          <DS.Button
            type="primary"
            icon={<PhoneOutlined />}
            disabled={!canEdit}
            onClick={() => {
              modalCallDeclarationRef.current.showModal(record, true).catch(console.warn);
            }}
          />
        </DS.Tooltip>
        <DS.Tooltip placement="topLeft" title="Ajouter un commentaire sur ce prospect">
          <DS.Button
            type="primary"
            icon={<CommentOutlined />}
            disabled={!canEdit}
            onClick={() => {
              modalCallDeclarationRef.current.showModal(record, false).catch(console.warn);
            }}
          />
        </DS.Tooltip>
        {!record?.archived && (
        <DS.Tooltip placement="topLeft" title="Archiver le démarchage de ce prospect">
          <DS.Button
            type="primary"
            style={{ background: '#f5222d', border: 0 }}
            icon={<UserDeleteOutlined />}
            disabled={!canEdit}
            onClick={() => {
              modalArchiveProspectRef.current.showModal(record, true);
            }}
          />
        </DS.Tooltip>
        )}
        {!record?.archived && !record?.validated && (
        <DS.Tooltip placement="topLeft" title="Finaliser le démarchage de ce prospect">
          <DS.Button
            type="primary"
            icon={<CheckOutlined />}
            style={{ background: '#52c41a', border: 0 }}
            disabled={!canEdit}
            onClick={() => {
              modalValidateProspectRef.current.showModal(record);
            }}
          />
        </DS.Tooltip>
        )}
      </DS.Space>
    </DS.Space>
  ),
});

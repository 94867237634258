/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/anchor-is-valid */
import * as DS from '@o-clock-dev/mooncake';
import { MailFilled, LinkOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import { useRef } from 'react';
import SearchableDropdown from 'src/pages/ProspectsList/SearchableDropdown';
import ucwords from 'ucwords';

export default (searchParams, showSuccess) => {
  const searchInput = useRef(null);
  return ({
    title: 'Nom complet',
    dataIndex: 'fullname',
    width: 200,
    filterDropdown: (filterProps) => (
      <SearchableDropdown ref={searchInput} key="fullname" {...filterProps} dataIndex="fullname" placeholder="Rechercher par nom, prénom ou adresse email" />
    ),
    defaultFilteredValue: searchParams.get('fullname') ? [searchParams.get('fullname')] : [],
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    render: (text, { firstname, lastname, sellsyID, email }) => (
      <>
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchParams.get('fullname')]}
          autoEscape
          textToHighlight={ucwords(`${firstname.toLowerCase()} ${lastname.toLowerCase()}`)}
        />
        <div>
          <DS.Tooltip placement="top" title="Voir la fiche sur sellsy">
            <a target="_blank" href={`https://www.sellsy.fr/thirds/prospect/${sellsyID}`} rel="noreferrer">
              <DS.Tag color="geekblue">
                <LinkOutlined />
              </DS.Tag>
            </a>
          </DS.Tooltip>
          <DS.Tooltip placement="right" title={`Copier l'adresse email "${email}" dans le presse papier`}>
            <a onClick={() => {
              navigator.clipboard.writeText(email);
              showSuccess(`L'adresse email ${email} a été copiée dans ton presse papier`);
            }}
            >
              <DS.Tag color="geekblue">
                <MailFilled />
              </DS.Tag>
            </a>
          </DS.Tooltip>
        </div>
      </>
    ),
    
    onFilter: (value, record) => `${record.firstname} ${record.lastname} ${record.email}`
      ?.toString()
      ?.toLowerCase()
      ?.includes(value?.toLowerCase()),
  });
};

import React from 'react';
import { Layout, Menu, Skeleton } from '@o-clock-dev/mooncake';

const { Header, Content } = Layout;
function MainLayoutSkeleton() {
  return (
    <Layout className="layout-skeleton">
      <Header>
        <div className="logo" />
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={['2']}
          items={[
            {
              label: <Skeleton.Button active style={{ backgroundColor: '#9f9f9f', width: 100 }} />,
            },
            {
              label: <Skeleton.Button active style={{ backgroundColor: '#9f9f9f', width: 100 }} />,
            },
            {
              label: <Skeleton.Button active style={{ backgroundColor: '#9f9f9f', width: 100 }} />,
            },
          ]}
        />
      </Header>
      <Content
        className="main-layout-content"
        style={{
          padding: '50px',
        }}
      >
        <div className="inner-layout-content inner-layout-content--skeleton">
          <Skeleton active round paragraph={{ rows: 0 }} />
          <Skeleton active round title={false} paragraph={{ rows: 10 }} />
          <Skeleton style={{ marginTop: 30 }} active round title={false} paragraph={{ rows: 5, width: [300, 500, 400, 450, 500] }} />
        </div>
      </Content>
    </Layout>
  );
}
export default MainLayoutSkeleton;

import parsePhoneNumber from 'libphonenumber-js';
import Highlighter from 'react-highlight-words';
import { useRef } from 'react';
import * as DS from '@o-clock-dev/mooncake';
import { PhoneOutlined, SearchOutlined } from '@ant-design/icons';
import SearchableDropdown from 'src/pages/ProspectsList/SearchableDropdown';

export default (searchParams) => {
  const searchInput = useRef(null);

  return {
    title: 'Téléphone',
    dataIndex: 'phone',
    width: 150,
    filterDropdown: (filterProps) => (
      <SearchableDropdown ref={searchInput} key="phone" {...filterProps} dataIndex="phone" placeholder="Rechercher par numéro de téléphone" />
    ),
    defaultFilteredValue: searchParams.get('phone') ? [searchParams.get('phone')] : [],
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => record.phone && parsePhoneNumber(record.phone)
      ?.formatNational()
      ?.replace(/ /g, '').includes(value?.replace(/ /g, '')),
    render: (text, { phone }) => {
      if (!phone) return null;
      const phoneNumber = parsePhoneNumber(phone);
      return (phoneNumber ? (
        <a href={phoneNumber.getURI()}>
          <DS.Tag color="#389e0d">
            <DS.Space>
              <PhoneOutlined />
              <Highlighter
                highlightClassName="highlight"
                searchWords={[searchParams.get('phone')?.replace(/(.{2})/g, '$1 ')?.trim()]}
                autoEscape
                textToHighlight={phoneNumber.formatNational() || ''}
              />
            </DS.Space>
          </DS.Tag>
        </a>
      ) : null
      );
    },
  };
};

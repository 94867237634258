import * as DS from '@o-clock-dev/mooncake';

export default (searchParams) => ({
  title: 'Appels',
  dataIndex: 'nbContacts',
  sorter: (left, right) => left.nbContacts - right.nbContacts,
  sortOrder: (searchParams.get('sort_by') === 'nbContacts' && searchParams.get('order_by')) || null,
  width: '5%',
  render: (text, { nbContacts }) => (
    <DS.Tag color="#faad14">
      <DS.Space>
        {nbContacts}
      </DS.Space>
    </DS.Tag>
  ),
});

import dayjs from 'dayjs';
import weekday from 'dayjs/plugin/weekday';
import relativeTime from 'dayjs/plugin/relativeTime';
import localeData from 'dayjs/plugin/localeData';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import 'dayjs/locale/fr';

dayjs.extend(localizedFormat);
dayjs.extend(weekday);
dayjs.extend(relativeTime);
dayjs.extend(localeData);

dayjs.locale('fr');

export default dayjs;

import * as DS from '@o-clock-dev/mooncake';
import { ClockCircleOutlined } from '@ant-design/icons';
import dayjs from 'src/utils/dayjs';

export default () => ({
  title: 'Dernier contact',
  dataIndex: 'last_contact',
  width: 150,
  render: (text, { comments }) => {
    const lastComment = comments?.[0];
    if (lastComment) {
      return (
        <DS.Tag color="blue">
          <DS.Space>
            <ClockCircleOutlined />
            {dayjs(lastComment.created).fromNow()}
          </DS.Space>
        </DS.Tag>
      );
    }
    return '';
  },
});

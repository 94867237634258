import * as DS from '@o-clock-dev/mooncake';
import { useCallback } from 'react';

export default ({
  searchParams,
  handleSave,
  statusParameters,
  modalCallDeclarationRef,
}) => {
  const mustShowModalCallDeclaration = useCallback((status) => [
    'noAnswerCallBack',
    'noAnswerVoiceMail',
    'answerNoMeet',
    'answerMeet',
    'meetDone',
  ].includes(status), []);
  
  const saveStatus = useCallback((value, prospect) => handleSave({ status: value }, prospect), [handleSave]);

  const declareCallAndSaveStatus = useCallback((value, prospect) => modalCallDeclarationRef.current.showModal(prospect, true)
    .catch(console.warn)
    .then(() => saveStatus(value, prospect)), [saveStatus]);

  const askForCallDeclarationAndSave = useCallback((value, record) => {
    DS.Modal.confirm({
      title: 'Déclarer un appel?',
      content: 'Voulez-vous déclarer un appel pour ce prospect?',
      okText: 'Oui',
      cancelText: 'Non',
      onOk: () => { declareCallAndSaveStatus(value, record); },
      onCancel: () => saveStatus(value, record),
    });
  }, [saveStatus, declareCallAndSaveStatus]);

  return {
    title: 'Status',
    dataIndex: 'status',
    width: 250,
    defaultFilteredValue: searchParams.get('status')?.trim() ? searchParams.get('status')?.split(',') : [],
    onFilter: (value, record) => record.status === value,
    filters: statusParameters.map((parameter) => ({ text: parameter.label, value: parameter.value })),
    render: (text, record) => (
      <DS.Form
        component={false}
        initialValues={{ status: record.status }}
      >
        <DS.Form.Item name="status">
          <DS.Select
            onChange={(value) => {
              if (mustShowModalCallDeclaration(value)) {
                return askForCallDeclarationAndSave(value, record);
              }
              return saveStatus(value, record);
            }}
            style={{ width: 220 }}
            loading={record.loading}
            disabled={record.loading}
            options={statusParameters.map((parameter) => ({ label: parameter.label, value: parameter.value }))}
          />
        </DS.Form.Item>
      </DS.Form>
    ),
  };
};

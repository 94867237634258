import * as DS from '@o-clock-dev/mooncake';

export default ({
  searchParams,
  handleSave,
  lvlOfInterestParameters,
}) => ({
  title: 'Nv. d\'intérêt',
  dataIndex: 'lvlOfInterest',
  width: 250,
  defaultFilteredValue: searchParams.get('lvlOfInterest')?.trim() ? searchParams.get('lvlOfInterest')?.split(',') : [],
  onFilter: (value, record) => Number(record.lvlOfInterest.id) === Number(value),
  filters: lvlOfInterestParameters.map((parameter) => ({ text: parameter.label, value: parameter.id })),
  render: (text, { lvlOfInterest, loading, ...restProps }) => (
    <DS.Form
      component={false}
      initialValues={{ lvlOfInterest: Number(lvlOfInterest.id) }}
    >
      <DS.Form.Item name="lvlOfInterest">
        <DS.Select
          onChange={(value, option) => handleSave({ lvlOfInterest: value }, { ...restProps, lvlOfInterest: option.children })}
          width={300}
          loading={loading}
          disabled={loading}
          options={lvlOfInterestParameters.map((parameter) => ({ label: parameter.label, value: Number(parameter.id) }))}
        />
      </DS.Form.Item>
    </DS.Form>
  ),
});

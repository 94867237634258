/* eslint-disable jsx-a11y/aria-role */
import { MainLayout } from 'src/layouts';
import { Navigate } from 'react-router-dom';

import ProspectsList from 'src/pages/ProspectsList';
import EventBright from 'src/pages/EventBright';
import PrivateRoute from './PrivateRoute';

export default [
  {
    path: '/',
    breadcrumb: 'Home',
    element: <MainLayout />,
    children: [
      {
        path: '/',
        element: <Navigate replace to="/prospects" />,
      },
      {
        path: 'eventBright',
        element: (
          <PrivateRoute role="eventbrite_import">
            <EventBright />
          </PrivateRoute>
        ),
      },
      {
        path: 'google-meet',
        element: (
          <PrivateRoute role="google_meet_import">
            <EventBright />
          </PrivateRoute>
        ),
      },
      {
        path: 'prospects',
        element: (
          <PrivateRoute role="prospects_list">
            <ProspectsList />
          </PrivateRoute>
        ),
      },
      {
        path: '*',
        element: <Navigate replace to="/" />,
      },
    ],
  },
];

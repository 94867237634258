import * as DS from '@o-clock-dev/mooncake';
import Highlighter from 'react-highlight-words';
import { useRef } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import SearchableDropdown from 'src/pages/ProspectsList/SearchableDropdown';

export default ({
  searchParams,
  handleSave,
  regionParameters = [],
  canEdit = false,
}) => {
  const searchInput = useRef(null);
  return {
    title: 'Région',
    dataIndex: 'region',
    width: 250,
    filterDropdown: (filterProps) => (
      <SearchableDropdown ref={searchInput} key="region" {...filterProps} dataIndex="region" placeholder="Rechercher par région" />
    ),
    defaultFilteredValue: searchParams.get('region') ? [searchParams.get('region')] : [],
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => record.region
      ?.toString()
      ?.toLowerCase()
      ?.includes(value?.toLowerCase()),
    render: (text, { region, loading, ...restProps }) => {
      if (!canEdit) {
        return (
          <Highlighter
            highlightClassName="highlight"
            searchWords={[searchParams.get('region') || '']}
            autoEscape
            textToHighlight={region}
          />
        );
      }
      if (!region) return null;
      return (
        <DS.Form
          component={false}
          initialValues={{ region }}
        >
          <DS.Form.Item name="region">
            <DS.Select
              onChange={(value, option) => handleSave({ region: value }, { ...restProps, region: option.children })}
              width={300}
              loading={loading}
              disabled={loading}
            >
              {regionParameters.map((regionparameter) => (
                <DS.Select.Option key={regionparameter.id} value={regionparameter.id}>
                  <Highlighter
                    highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
                    searchWords={[searchParams.get('region') || '']}
                    autoEscape
                    textToHighlight={regionparameter.label}
                  />
                </DS.Select.Option>
              ))}
            </DS.Select>
          </DS.Form.Item>
        </DS.Form>
      );
    },
  };
};

import React, { useRef, useState, useEffect, useCallback } from 'react';
import * as DS from '@o-clock-dev/mooncake';
import dayjs from 'src/utils/dayjs';
import { useRecoilState } from 'recoil';
import { identitySelector } from '@recoil/auth';
import { useAuthorization } from 'src/commons/KeycloakProvider';
import { useSearchParams } from 'react-router-dom';
import ApiCaller from 'src/commons/ApiCaller';
import { useError, useSuccess } from 'src/commons/MessagesProvider';
import RefreshButton from 'src/components/RefreshButton';
import ModalDeclarationAppel from './ModalDeclarationAppel';

import './style.scss';
import ModalArchivageProspect from './ModalArchivageProspect';
import ModalValidateProspect from './ModalValidateProspect';
import * as columns from './columns';

const expandable = {
  expandedRowRender: (record) => (
    <DS.List
      style={{ maxWidth: '100%', width: 800 }}
      size="small"
      header={<div>Commentaire sur ce prospect</div>}
      dataSource={record.comments}
      renderItem={(comment) => {
        const splittedComment = comment.description.split('</aside>');
        let authorText = null;
        let commentText = comment.description;
        if (splittedComment.length > 1) {
          authorText = splittedComment[0].substr(7);
          authorText = authorText.substr(0, authorText.length - 10);
          commentText = splittedComment[1];
        }
        return (
          <DS.List.Item
            key={comment._id}
          >
            <DS.List.Item.Meta
              style={{ width: 300 }}
              avatar={authorText ? (<DS.Avatar>{authorText[0]}</DS.Avatar>) : <DS.Avatar>?</DS.Avatar>}
              title={authorText || 'Utilisateur inconnu'}
              description={`Le ${dayjs(comment.created).format('DD/MM/YYYY à HH:mm')}`}
            />
            <div style={{ flex: 1 }} dangerouslySetInnerHTML={{ __html: commentText }} />
          </DS.List.Item>
        );
      }}
    />
  ),
};

function ProspectsList() {
  const [searchParams, setSearchParams] = useSearchParams();
  const [prospects, setProspects] = useState([]);
  const [tableData, setTableData] = useState({
    limit: Number(searchParams.get('limit')) || 10,
    nextPage: (Number(searchParams.get('page')) || 1) + 1,
    offset: ((Number(searchParams.get('page')) || 1) * (Number(searchParams.get('limit')) || 10)) - (Number(searchParams.get('limit')) || 10),
    page: (Number(searchParams.get('page')) || 1),
    totalDocs: 0,
    totalPages: 0,
  });
  const [tableLoading, setTableLoading] = useState(false);
  const [regionParameters, setRegionParameters] = useState([]);
  const [lvlOfInterestParameters, setLvlOfInterestParameters] = useState([]);
  const [statusParameters, setStatusParameters] = useState([]);
  const modalCallDeclarationRef = useRef();
  const modalArchiveProspectRef = useRef();
  const modalValidateProspectRef = useRef();
  const canEditProspect = useAuthorization('prospects_edit');

  const [userIdentity] = useRecoilState(identitySelector);

  const showSuccess = useSuccess();
  const showError = useError();

  const updateProspectsList = useCallback((prospectsList) => {
    setProspects(prospectsList.map((prospect) => ({
      key: prospect.sellsyID,
      ...prospect,
      region: prospect?.region?.label,
      comments: prospect.comments.sort((first, second) => new Date(second.created) - new Date(first.created)),
    })));
  }, []);

  const refreshProspects = useCallback(() => {
    setTableLoading(true);
    return ApiCaller.makeRequest('GET', `/prospects?${searchParams.toString()}`)
      .then(({
        prospects: {
          docs: prospectsList,
          ...currentTableData
        },
        regionCustomField,
        lvlOfInterestCustomField,
        statusField,
      }) => {
        setTableData(currentTableData);
        updateProspectsList(prospectsList);
        setRegionParameters(regionCustomField?.parameters?.items || []);
        setLvlOfInterestParameters(lvlOfInterestCustomField?.parameters?.items || []);
        setStatusParameters(statusField || []);
      }).finally(() => {
        setTableLoading(false);
      });
  }, [searchParams]);

  useEffect(() => {
    // Récupération de la liste des prospects
    setTableLoading(true);
    refreshProspects();
  }, [searchParams]);

  const handleChange = (pagination, filters, sorter) => {
    const newSearchParams = new URLSearchParams();
    Object.keys(filters).filter((filterKey) => filters[filterKey]).forEach((filterKey) => {
      if (filters && filters[filterKey].length > 0) {
        newSearchParams.set(filterKey, filters[filterKey].join(','));
      }
    });

    if (sorter.field && sorter.order) {
      newSearchParams.set('sort_by', sorter.field);
      newSearchParams.set('order_by', sorter.order);
    }
    newSearchParams.set('page', pagination?.current?.toString() || '1');
    newSearchParams.set('limit', pagination?.pageSize?.toString() || '10');

    setSearchParams(newSearchParams);
  };

  const handleProspectUpdate = useCallback((values, row) => {
    const newData = [...prospects];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, loading: true });
    setProspects(newData);

    return ApiCaller.makeRequest('PATCH', `/prospects/${row._id}`, values)
      .then(() => {
        const newData2 = [...prospects];
        const index2 = newData.findIndex((item2) => row.key === item2.key);
        const item2 = newData[index2];
        newData.splice(index2, 1, { ...item2, ...row, loading: false });
        setProspects(newData2);
        showSuccess('La prospect a bien été mis à jour', 'Prospect mis à jour');
      })
      .catch((error) => {
        showError(error.message, 'Erreur durant la mise à jour du prospect');
        const newData2 = [...prospects];
        const index2 = newData.findIndex((item2) => row.key === item2.key);
        const item2 = newData[index2];
        newData.splice(index2, 1, { ...item2, loading: false });
        setProspects(newData2);
      });
  }, [prospects]);

  const onProspectListUpdated = useCallback((prospect) => {
    const newData = [...prospects];
    const index = newData.findIndex((item) => prospect.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, { ...item, ...prospect });
    setProspects(newData);
  }, [prospects]);

  const columnsList = [
    columns.fullname(searchParams, showSuccess),
    columns.importation(searchParams),
    columns.phone(searchParams),
    columns.lvlOfInterest({
      searchParams,
      handleSave: handleProspectUpdate,
      lvlOfInterestParameters,
    }),
    columns.status({
      searchParams,
      handleSave: handleProspectUpdate,
      statusParameters,
      modalCallDeclarationRef,
    }),
    columns.region({
      searchParams,
      canEdit: canEditProspect,
      handleSave: handleProspectUpdate,
      regionParameters,
    }),
    columns.origin(searchParams),
    columns.nbContacts(searchParams),
    columns.opportunities(),
    columns.lastContact(),
    columns.state(searchParams),
    columns.actions({
      searchParams,
      userIdentity,
      onProspectListUpdated,
      canEdit: canEditProspect,
      modalCallDeclarationRef,
      modalArchiveProspectRef,
      modalValidateProspectRef,
    }),
  ].filter((column) => column);

  return (
    <>
      <div className="prospects-list">
        <DS.Table
          columns={columnsList}
          dataSource={prospects}
          loading={tableLoading}
          expandable={expandable}
          onChange={handleChange}
          rowClassName={(record) => {
            let className = 'editable-row ';
            className += record?.reservation?.keycloakUserId ? 'reserved-row ' : '';
            className += record?.reservation?.keycloakUserId === userIdentity?.sub ? 'own-reserved-row ' : '';
            return className;
          }}
          pagination={{
            pageSize: Number(tableData.limit),
            showTotal: (total) => `${tableData.totalDocs || total} prospects`,
            total: tableData.totalDocs,
            pageSizeOptions: [10, 25, 50],
            showSizeChanger: true,
            current: tableData.page || 1,
          }}
        />
        <RefreshButton onClick={refreshProspects} loading={tableLoading} />
      </div>
      <ModalDeclarationAppel
        ref={modalCallDeclarationRef}
        onDeclarationAdded={updateProspectsList}
      />
      <ModalArchivageProspect
        ref={modalArchiveProspectRef}
        onArchived={updateProspectsList}
      />
      <ModalValidateProspect
        ref={modalValidateProspectRef}
        onValidated={updateProspectsList}
      />
    </>
  );
}

export default ProspectsList;

import * as DS from '@o-clock-dev/mooncake';
import Highlighter from 'react-highlight-words';
import { useRef } from 'react';
import { SearchOutlined } from '@ant-design/icons';
import SearchableDropdown from 'src/pages/ProspectsList/SearchableDropdown';

const colorByOrigin = (origin) => {
  const colors = JSON.parse(process.env.COLOR_BY_ORIGIN || '{}');
  return colors[origin] || colors.default || '#b5b5b5';
};

export default (searchParams) => {
  const searchInput = useRef(null);
  return {
    title: 'Origine',
    dataIndex: 'origin',
    width: 150,
    filterDropdown: (filterProps) => (
      <SearchableDropdown ref={searchInput} key="origin" {...filterProps} dataIndex="origin" placeholder="Rechercher par origine" />
    ),
    defaultFilteredValue: searchParams.get('origin') ? [searchParams.get('origin')] : [],
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    filterIcon: (filtered) => (
      <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />
    ),
    onFilter: (value, record) => record.origin
      ?.toString()
      ?.toLowerCase()
      ?.includes(value?.toLowerCase()),
    render: (text) => (
      <DS.Tag color={colorByOrigin(text)}>
        <Highlighter
          highlightClassName="highlight"
          searchWords={[searchParams.get('origin')]}
          autoEscape
          textToHighlight={text || ''}
        />
      </DS.Tag>
    ),
  };
};

import React from 'react';
import PropTypes from 'prop-types';
import { ReloadOutlined } from '@ant-design/icons';
import './styles.scss';

function RefreshButton({ onClick, loading }) {
  return (
    <button
      type="button"
      className={`refresh-button ${loading ? 'refresh-button--loading' : ''}`}
      onClick={onClick}
    >
      <ReloadOutlined />
    </button>
  );
}

RefreshButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};
RefreshButton.defaultProps = {
  loading: false,
};

export default RefreshButton;

import { useState } from 'react';
import { UploadOutlined, InboxOutlined } from '@ant-design/icons';
import { Card, Upload, message, Button } from '@o-clock-dev/mooncake';
import ApiCaller from 'src/commons/ApiCaller';
import './style.scss';

const UploadEventBright = () => {
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false);

  const handleUpload = () => {
    const formData = new FormData();
    fileList.forEach(file => {
      formData.append('csvFile', file);
    });
    setUploading(true);

    // You can use any AJAX library you like
    ApiCaller.makeRequest('POST', '/eventBright', formData)
      .then(() => {
        // setFileList([]);
        message.success('upload successfully.');
      })
      .catch(() => {
        message.error('upload failed.');
      })
      .finally(() => {
        setUploading(false);
      });
  };

  const uploadProps = {
    maxCount: 1,
    multiple: false,
    accept: "text/csv",
    onRemove: file => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: file => {
      setFileList([file]);

      return false;
    },
    fileList: [],
  };

  return (
    <div className="eventbright">
      <Upload.Dragger {...uploadProps}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Clique ou fais glisser le fichier dans cette zone pour l'envoyer
        </p>
        <p className="ant-upload-hint">
          Supporte uniquement les fichiers CSV, attention de bien vérifier que la structure est correcte
        </p>
        <Upload
          action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
          listType="picture-card"
          fileList={fileList}
        >
        </Upload>
      </Upload.Dragger>
      <Button
        type="primary"
        multiple
        onClick={handleUpload}
        disabled={fileList.length === 0}
        loading={uploading}
        style={{ marginTop: 16 }}
      >
        {uploading ? 'En cours d\'importation' : 'Démarrer l\'importation'}
      </Button>
    </div >
  );
};

export default UploadEventBright;

import dayjs from 'src/utils/dayjs';
import * as DS from '@o-clock-dev/mooncake';
import { ClockCircleOutlined } from '@ant-design/icons';

export default (searchParams) => ({
  title: 'Importation',
  dataIndex: 'createdDate',
  width: 150,
  sorter: (left, right) => (!left.createdDate ? 1 : dayjs(left.createdDate).diff(right.createdDate, 'days')),
  sortOrder: (searchParams.get('sort_by') === 'createdDate' && searchParams.get('order_by')) || null,
  render: (text, { createdDate }) => (
    <DS.Tag color="blue">
      <DS.Space>
        <ClockCircleOutlined />
        {createdDate ? dayjs(createdDate).fromNow() : 'Inconnue'}
      </DS.Space>
    </DS.Tag>
  ),
});

/*
 * Package Import
 */
import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { ReactKeycloakProvider, useKeycloak } from '@react-keycloak/web';
import jwtDecode from 'jwt-decode';
import { useSetRecoilState } from 'recoil';

/*
 * Local Import
 */
import ApiCaller from 'src/commons/ApiCaller';
import { withMessages } from 'src/commons/MessagesProvider';
import { identitySelector } from '@recoil/auth';
import PropTypes from 'prop-types';
import MainLayoutSkeleton from 'src/layouts/MainLayoutSkeleton';
import keycloakInstance from './Keycloak';

export const isAuthorized = (keycloak) => (role) => {
  let detailedRole = role;
  let ressource = null;
  const explodedRole = role.split(':');
  if (explodedRole.length === 2) {
    detailedRole = explodedRole[1];
    ressource = explodedRole[0];
  }
  return (
    keycloak.hasRealmRole(detailedRole)
    || keycloak.hasResourceRole(detailedRole, ressource)
  );
};

export const useAuthorization = (roles = [], ORMode = true) => {
  const { keycloak } = useKeycloak();
  return isAuthorized(keycloak, ORMode)(roles);
};

export const withKeycloak = (WrappedComponent) => function (props) {
  const { keycloak } = useKeycloak();
  return <WrappedComponent keycloak={keycloak} {...props} />;
};

export const withAuthorization = (WrappedComponent) => function (props) {
  const { keycloak } = useKeycloak();
  return (
    <WrappedComponent isAuthorized={isAuthorized(keycloak)} {...props} />
  );
};

function KeycloakProvider({ children, showError }) {
  const [isReady, setIsReady] = useState(false);
  const setIdentity = useSetRecoilState(identitySelector);

  const programRefreshToken = useCallback((tokens) => {
    if (tokens.token) {
      const decoded = jwtDecode(tokens.token);
      const delay = decoded.exp * 1000 - new Date().getTime() - 10000;
      setTimeout(() => {
        keycloakInstance
          .updateToken(60)
          .then((refreshed) => {
            if (refreshed) {
              const newTokens = {
                token: keycloakInstance.token,
                refreshToken: keycloakInstance.refreshToken,
                idToken: keycloakInstance.idToken,
              };
              programRefreshToken(newTokens);
            }
          })
          .catch((error) => {
            console.error(error);
            showError('Une erreur est survenue durant le maintien de la session, vous allez être redirigé vers la page de connexion');
            localStorage.removeItem('kcTokens');
            setTimeout(() => {
              window.location.reload();
            }, 10000);
          });
      }, delay);
    }
  }, [keycloakInstance]);

  const keycloakTokens = useMemo(() => {
    const tokens = JSON.parse(localStorage.getItem('kcTokens') || '{}');
    if (tokens && tokens.refreshToken) {
      const decoded = jwtDecode(tokens.refreshToken);
      if (Math.round(new Date().getTime() / 1000) > decoded.exp) {
        localStorage.removeItem('kcTokens');
        return {};
      }
      programRefreshToken(tokens);
    }
    return tokens;
  }, []);

  const logoutUser = useCallback(() => {
    showError(
      'Vous avez été déconnecté de keycloak, vous allez être redirigé dans 2 secondes',
    );
    setTimeout(setIdentity.bind(this, null), 2000);
  }, []);

  useEffect(() => {
    ApiCaller.eventEmitter.on('error_403', logoutUser);
    return () => {
      ApiCaller.eventEmitter.off('error_403', logoutUser);
    };
  }, []);

  const relogWithToken = (token) => {
    if (token) {
      ApiCaller.setToken(token);

      try {
        setIdentity(jwtDecode(token));
        setIsReady(true);
      }
      catch (error) {
        showError(error);
      }
    }
  };

  const eventLogger = (event, error) => {
    switch (event) {
      case 'onReady': {
        if (!keycloakInstance.authenticated) {
          keycloakInstance.login();
        }
        break;
      }
      case 'onAuthError': {
        showError(
          decodeURIComponent(
            error?.error_description
            || 'Une erreur inconnue est survenue, contacte le service technique si l\'erreur persiste !',
          ).replace(/\+/g, ' '),
        );
        localStorage.removeItem('kcTokens');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        break;
      }
      case 'onInitError': {
        console.error("Une erreur est survenue à l'initialisation de Keycloak", error);
        showError('Une erreur est survenue durant l\'authentification');
        localStorage.removeItem('kcTokens');
        setTimeout(() => {
          window.location.reload();
        }, 2000);
        break;
      }
      default:
        break;
    }
  };

  const onTokens = (tokens) => {
    localStorage.setItem('kcTokens', JSON.stringify(tokens));

    if (ApiCaller.token !== tokens.token) {
      relogWithToken(tokens.token);
    }
  };

  return (
    <ReactKeycloakProvider
      authClient={keycloakInstance}
      onEvent={eventLogger}
      onTokens={onTokens}
      initOptions={{
        ...keycloakTokens,
      }}
    >
      {isReady && keycloakInstance.authenticated ? (
        children
      ) : (
        <MainLayoutSkeleton />
      )}
    </ReactKeycloakProvider>
  );
}

KeycloakProvider.propTypes = {
  children: PropTypes.any,
  showError: PropTypes.func,
};

export default withMessages(KeycloakProvider);

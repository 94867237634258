import { Button, Tooltip, notification, Popconfirm } from '@o-clock-dev/mooncake';
import { useCallback } from 'react';
import { CheckCircleOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useAuthorization } from 'src/commons/KeycloakProvider';
import ApiCaller from 'src/commons/ApiCaller';
import { useRecoilState } from 'recoil';
import { identitySelector } from '@recoil/auth';
import dayjs from 'src/utils/dayjs';
import PropTypes from 'prop-types';

function ReservationButton({ record, onDone }) {
  const canEditProspect = useAuthorization('prospects_edit');
  const [userIdentity] = useRecoilState(identitySelector);

  const changeReservationState = useCallback((reservationState = true) => {
    if (!canEditProspect) return false;
    return ApiCaller.makeRequest('PATCH', `/prospects/${record._id}/reservation`, { reservationState })
      .then((prospect) => {
        if (!prospect.reservation) {
          prospect.reservation = {};
        }
        onDone?.({ ...record, ...prospect });
      })
      .catch((error) => {
        notification.error({
          message: 'Erreur durant la réservation du prospect',
          description: error.message,
        });
      });
  }, [record]);

  const setReservationStateTrue = useCallback(() => { changeReservationState(true); }, [changeReservationState, record]);
  const setReservationStateFalse = useCallback(() => { changeReservationState(false); }, [changeReservationState, record]);

  let ButtonComponent = (
    <Button
      type="primary"
      style={{ background: record?.reservation?.keycloakUserId ? '#fa8c16' : '#52c41a', border: 0 }}
      icon={record?.reservation?.keycloakUserId ? <ExclamationCircleOutlined /> : <CheckCircleOutlined />}
      onClick={() => {
        if (!record?.reservation?.keycloakUserId) {
          changeReservationState(record, true);
        }
      }}
      disabled={!canEditProspect}
    />
  );

  if (canEditProspect && record?.reservation?.keycloakUserId === userIdentity?.sub) {
    ButtonComponent = (
      <Popconfirm
        title="Tu es sur de vouloir libérer ce prospect ?"
        onConfirm={setReservationStateFalse}
        okText="Oui je veux libérer ce prospect"
        cancelText="Nope !"
      >
        {ButtonComponent}
      </Popconfirm>
    );
  }
  else if (canEditProspect && record?.reservation?.keycloakUserId) {
    ButtonComponent = (
      <Popconfirm
        title={`Ce prospect a été attribué à ${record?.reservation?.keycloakUsername} ${dayjs(record?.reservation?.reservedDate).fromNow()}, veux-tu lui enlever et te l'attribuer ?`}
        onConfirm={setReservationStateTrue}
        okText="Oui je veux m'attribuer ce prospect"
        cancelText="Nope !"
      >
        {ButtonComponent}
      </Popconfirm>
    );
  }

  return (
    <Tooltip
      placement="left"
      title={
            record?.reservation?.keycloakUsername
              ? `Prospect réservé par ${record?.reservation?.keycloakUsername} ${dayjs(record?.reservation?.reservedDate).fromNow()}`
              : 'Réserver ce prospect'
}
    >
      {ButtonComponent}
    </Tooltip>
  );
}

ReservationButton.propTypes = {
  record: PropTypes.object.isRequired,
  onDone: PropTypes.func,
};

export default ReservationButton;

import * as DS from '@o-clock-dev/mooncake';
import { LinkOutlined } from '@ant-design/icons';

export default () => ({
  title: 'Opportunités',
  dataIndex: 'opportunities',
  render: (text, { opportunities }) => {
    const filteredOpportunities = opportunities.map((opportunity) => {
      let color = opportunity.name.length > 5 ? 'geekblue' : 'green';
      if (Number(opportunity.pipeline?.id) === Number(process.env.PIPELINE_PROSPECTION_SELLSY_ID)) {
        return null;
      }
      if (Number(opportunity.pipeline?.id) === Number(process.env.PIPELINE_DEV_WEB_SELLSY_ID)) {
        color = 'cyan';
      }
      return (
        <a key={opportunity.id} target="_blank" href={`https://www.sellsy.fr/prospection/opportunities/${opportunity.id}`} rel="noreferrer">
          <DS.Tag color={color}>
            <DS.Space>
              <LinkOutlined />
              {opportunity.name.toUpperCase()}
            </DS.Space>
          </DS.Tag>
        </a>
      );
    }).filter((opportunity) => opportunity);

    if (filteredOpportunities.length === 0) {
      return (
        <DS.Tag color="red">
          Aucune opportunité autre que démarchage
        </DS.Tag>
      );
    }
    return filteredOpportunities;
  },
});

import * as DS from '@o-clock-dev/mooncake';
import dayjs from 'src/utils/dayjs';

export default (searchParams) => ({
  title: 'Filtrer par',
  dataIndex: 'state',
  width: 500,
  defaultFilteredValue: searchParams.get('state')?.trim() ? searchParams.get('state')?.split(',') : [],
  render: (text, { archived, validated, opportunities }) => {
    const tags = [];
    opportunities.forEach((opportunity) => {
      if (
        opportunity.rituelResult >= 0
        && Number(opportunity.pipeline?.id) === Number(process.env.PIPELINE_DEV_WEB_SELLSY_ID)
      ) {
        const admissible = opportunity.status === 'won';
        tags.push(
          <DS.Tag key={opportunity.id} color={admissible ? 'cyan' : 'orange'}>
            <b>{admissible ? 'Admissible' : 'Non admissible'}</b> pour <b>{opportunity.name}</b> avec un score de <b>{opportunity.rituelResult}</b>
          </DS.Tag>,
        );
      }
      else if (
        opportunity.rituelResult === undefined
        && Number(opportunity.pipeline?.id) === Number(process.env.PIPELINE_DEV_WEB_SELLSY_ID)
      ) {
        tags.push(
          <DS.Tag key={`noRituel_${opportunity.id}`} color="red">
            Pas de rituel passé pour <b>{opportunity.name}</b>
          </DS.Tag>,
        );
      }
      return null;
    });
    if (archived) {
      tags.push(
        <DS.Tag key="archived" color="grey">
          Archivé
        </DS.Tag>,
      );
    }
    if (validated) {
      tags.push(
        <DS.Tooltip key="validated" title={`Validé le ${dayjs(validated?.date).format('LLLL')}${validated?.validatedBy ? ` par ${validated?.validatedBy}` : ''}`}>
          <DS.Tag color="green">
            Transformé
          </DS.Tag>
        </DS.Tooltip>,
      );
    }
    return tags;
  },
  filters: [
    {
      text: 'Rituel passé',
      value: 'withScore',
    },
    {
      text: 'Rituel non passé',
      value: 'withoutScore',
    },
    {
      text: 'Archivé',
      value: 'withArchived',
    },
    {
      text: 'Admissible',
      value: 'withAdmissible',
    },
  ],
  onFilter: (value, record) => {
    if (value === 'withScore') {
      return record.opportunities
        .find((opportunity) => opportunity.rituelResult >= 0
        && Number(opportunity.pipeline?.id) === Number(process.env.PIPELINE_DEV_WEB_SELLSY_ID));
    }
    if (value === 'withoutScore') {
      return record.opportunities
        .find((opportunity) => opportunity.rituelResult === undefined
        && Number(opportunity.pipeline?.id) === Number(process.env.PIPELINE_DEV_WEB_SELLSY_ID));
    }
    if (value === 'withArchived') {
      return record.archived;
    }
    if (value === 'withAdmissible') {
      return record.validated;
    }
      
    return true;
  },
});

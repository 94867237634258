import * as DS from '@o-clock-dev/mooncake';
import React, { useState, useImperativeHandle, useCallback, useRef } from 'react';
import ApiCaller from 'src/commons/ApiCaller';
import { useError, useSuccess } from 'src/commons/MessagesProvider';
import PropTypes from 'prop-types';

const ModalDeclarationAppel = React.forwardRef(({ onDeclarationAdded }, ref) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [withStepIncrementation, setWithStepIncrementation] = useState(false);
  const [comment, setCommentValue] = useState('');
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [prospect, setProspect] = useState({});

  const showSuccess = useSuccess();
  const showError = useError();

  const promiseCallbacks = useRef(null);

  const onCommentChange = useCallback((event) => {
    setCommentValue(event.target?.value);
  }, []);

  const handleOk = useCallback(() => {
    setConfirmLoading(true);

    if (promiseCallbacks.current?.withoutUpdate) {
      setIsModalVisible(false);
      setCommentValue('');
      setConfirmLoading(false);
      return promiseCallbacks.current?.resolve(comment);
    }

    return ApiCaller.makeRequest('POST', `/prospects/${prospect?._id}/comment`, {
      comment,
      withStepIncrementation,
    })
      .then((prospectsList) => {
        promiseCallbacks.current?.resolve(comment);
        showSuccess(withStepIncrementation ? 'La déclaration d\'appel a été créée avec succès' : 'Le commentaire a été créé avec succès', 'Appel ajouté');
        setIsModalVisible(false);
        setCommentValue('');
        onDeclarationAdded?.(prospectsList);
      }).catch((error) => {
        let message = error.message;
        if (error?.response?.data?.message) {
          message = error?.response?.data?.message;
        }
        showError(message, 'Erreur durant la déclaration de l\'appel');
        promiseCallbacks.current?.reject(error);
      }).finally(() => {
        setConfirmLoading(false);
      });
  }, [comment, prospect, withStepIncrementation]);

  const handleCancel = () => {
    promiseCallbacks.current?.reject(new Error('Annulation de la déclaration d\'appel'));
    setIsModalVisible(false);
    setProspect({});
    setCommentValue('');
    setWithStepIncrementation(false);
  };

  useImperativeHandle(ref, () => ({
    showModal: (_prospect, stepIncrementation = true, withoutUpdate = false) => new Promise((resolve, reject) => {
      promiseCallbacks.current = { resolve, reject, withoutUpdate };
      setWithStepIncrementation(stepIncrementation);
      setProspect(_prospect);
      setIsModalVisible(true);
      setCommentValue('');
    }),
  }), []);

  return (
    <DS.Modal
      title={`${withStepIncrementation ? 'Déclaration d\'un appel' : 'Ajout d\'un commentaire'} à ${prospect?.firstname} ${prospect?.lastname}`}
      open={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
      cancelText="Annuler"
      okText={withStepIncrementation ? 'Déclarer l\'appel' : 'Ajouter un commentaire'}
    >
      <DS.Input.TextArea
        rows={4}
        onChange={onCommentChange}
        value={comment}
        placeholder={`Commentaire à ajouter à ${prospect?.firstname} ${prospect?.lastname}`}
      />
    </DS.Modal>
  );
});

ModalDeclarationAppel.propTypes = {
  onDeclarationAdded: PropTypes.func,
};

export default ModalDeclarationAppel;

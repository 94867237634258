import React, { useMemo } from 'react';
import { Layout, Menu } from '@o-clock-dev/mooncake';
import { NavLink, Outlet } from 'react-router-dom';
import { useAuthorization } from 'src/commons/KeycloakProvider';

const { Header, Content } = Layout;
function MainLayout() {
  const isEventBrigthImportAuthorized = useAuthorization('eventbrite_import');
  // const isGoogleMeetImportAuthorized = useAuthorization('google_meet_import');
  const canListProspects = useAuthorization('prospects_list');

  const menuItems = useMemo(() => {
    const items = [];
    if (isEventBrigthImportAuthorized) {
      items.push({ label: <NavLink to="/eventBright">Import EventBright</NavLink>, key: 'eventbright' });
    }
    // if (isGoogleMeetImportAuthorized) {
    //   items.push({ label: <Link to="/googleMeet">Google Meet</Link>, key: 'google-meet' })
    // }
    if (canListProspects) {
      items.push({ label: <NavLink to="/prospects">Liste des prospects</NavLink>, key: 'prospects' });
    }
    return items;
  }, []);

  return (
    <Layout className="layout">
      <Header>
        <div className="logo" />
        <Menu
          theme="dark"
          mode="horizontal"
          defaultSelectedKeys={['2']}
          items={menuItems}
        />
      </Header>
      <Content
        className="main-layout-content"
      >
        <div className="inner-layout-content">
          <Outlet />
        </div>
      </Content>
    </Layout>
  );
}
export default MainLayout;

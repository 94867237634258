import * as DS from '@o-clock-dev/mooncake';
import React, { useState, useImperativeHandle, useCallback } from 'react';
import ApiCaller from 'src/commons/ApiCaller';
import { useError, useSuccess } from 'src/commons/MessagesProvider';
import PropTypes from 'prop-types';

const ModalValidateProspect = React.forwardRef(({ onValidated }, ref) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [comment, setCommentValue] = useState('');
  const [confirmLoading, setConfirmLoading] = useState(false);
  const [prospect, setProspect] = useState({});

  const showSuccess = useSuccess();
  const showError = useError();

  const onCommentChange = useCallback((event) => {
    setCommentValue(event.target?.value);
  }, []);

  const handleOk = () => {
    setConfirmLoading(true);

    ApiCaller.makeRequest('PUT', `/prospects/${prospect?._id}/validate`, {
      comment,
    })
      .then((prospectsList) => {
        showSuccess('Le prospect a été validé avec succès', 'Prospect validé');
        setIsModalVisible(false);
        setCommentValue('');
        onValidated?.(prospectsList);
      }).catch((error) => {
        let message = error.message;
        if (error?.response?.data?.message) {
          message = error?.response?.data?.message;
        }
        showError(message, 'Erreur durant le changement la validation du prospect');
      }).finally(() => {
        setConfirmLoading(false);
      });
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setProspect({});
    setCommentValue('');
  };

  useImperativeHandle(ref, () => ({
    showModal: (_prospect) => {
      setProspect(_prospect);
      setIsModalVisible(true);
      setCommentValue('');
    },
  }), []);

  return (
    <DS.Modal
      title={`Passage en "Gagnée" de l'opportunité de démarchage du prospect ${prospect?.firstname} ${prospect?.lastname}`}
      open={isModalVisible}
      onOk={handleOk}
      onCancel={handleCancel}
      confirmLoading={confirmLoading}
      cancelText="Annuler"
      okText="Valider le prospect"
    >
      <DS.Input.TextArea
        rows={4}
        onChange={onCommentChange}
        value={comment}
        placeholder={`Commentaire à ajouter à ${prospect?.firstname} ${prospect?.lastname} pour le passage de l'opportunité "Démarchage" en "Gagnée"`}
      />
    </DS.Modal>
  );
});

ModalValidateProspect.propTypes = {
  onValidated: PropTypes.func,
};

export default ModalValidateProspect;
